import React, {Component} from 'react';
import {editSurvey} from "../../redux/actions";
import {CustomMenuItem, FieldTitle, FormContainer, ImageContainer, ImageContentContainer, ImageInput,
  Layout, PageTitle, RemovePhotoButton, SaveButton, StyledInput,
  TitleContainer} from "../CreateEvent/styles";
import SurveyQuestions  from '../SurveyQuestions'
import { ActivityDropdown, ActivityTypeContainer } from '../CreateChallengeForm/styles';
import Waiting from "../Waiting";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { /* challengePointItems, */ surveyOptionTypes } from '../../../mockData';
import {checkImage, getOrientation, resetOrientation} from "../../utils/methods";
import {imageErrorMessage, ImageUrl} from "../../utils/constants";
import {toast} from "react-toastify";
import _ from 'lodash';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';


class EditSurveys extends Component{
  constructor(props) {
    super(props);
    let pointsArray = this.challengePointItems;
    let indexExists = this.challengePointItems.findIndex((data) => data === 'No points');
    indexExists === -1 && pointsArray.unshift("No points");
    this.state = {
      surveyId: 0,
      title: '',
      imgSrc: '',
      imageName: '',
      imageUpdated: 0,
      points: 'Tier 1: 25 points',
      description:'',
      surveyPoints: pointsArray,
      error: [],
      hasSections: 1,
      surveyDetails: {},
      prevSectionsData: [
        {
          "title": '',
          "data": [
            {
              "question": '',
              "options": surveyOptionTypes[0].options,
              "question_type": surveyOptionTypes[0].questionType
            }
          ]
        }
      ],
      prevQuestionsData: [
        {
          "question" : '',
          "options": surveyOptionTypes[0].options,
          "question_type": surveyOptionTypes[0].questionType
        }
      ],
      questions: [],
      buttonDisable: true,
      sections: []
    };
  }

  challengePointItems= [
    "Tier 1: 25 points",
    "Tier 2: 100 points",
    "Tier 3: 250 points",
    "Tier 4: 500 points"
  ]

  componentDidMount() {
    const { surveyDetails, history } = this.props;
    if(!surveyDetails || _.isEmpty(surveyDetails)) {
      history.push('/company/surveys/show-all');
    } else {
      this.setState({
        surveyId: surveyDetails.id,
        imgSrc: surveyDetails.image,
        title: surveyDetails.survey_name,
        description: surveyDetails.description,
        imageName: surveyDetails.image,
        hasSections: surveyDetails.has_sections,
        points: surveyDetails.survey_point === 0 ? 'No points' :
          surveyDetails.survey_point === 25 ? "Tier 1: 25 points" :
            surveyDetails.survey_point === 100 ? "Tier 2: 100 points" :
              surveyDetails.survey_point === 250 ? "Tier 3: 250 points" :
                surveyDetails.survey_point === 500 ? "Tier 4: 500 points" : "Tier 1: 25 points",
        sections: surveyDetails.has_sections === 1 ? surveyDetails.data : [],
        questions: surveyDetails.has_sections !== 1 ? surveyDetails.data : [],
      }, () => { this.buttonDisableFunc() });
    }

  }
  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onSelectPoints = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if(e.target.files[0]) {
      if(checkImage(fileArr)) {
        if((file?.size/1000000) <= 20){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('survey-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
              imageUpdated: 1
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('survey-upload-file').value = '';
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('survey-upload-file').value = '';
      }
    }
  };

  saveSurvey = (e) => {
    e.preventDefault();
    const{history, editSurvey} = this.props;
    const {title, imgSrc, points, description, hasSections, sections, questions, surveyId, imageUpdated} = this.state;
    let obj = {
      'title': title,
      'survey_id': surveyId,
      'image': imgSrc,
      'description': description,
      'survey_point': points === 'No points' ? 0 : points === "Tier 1: 25 points" ? 25 : points === "Tier 2: 100 points" ? 100 : points === "Tier 3: 250 points" ? 250 : 500,
      'has_sections': hasSections,
      'imageUpdated': imageUpdated,
      'data': hasSections !== 0 ? sections : questions,
    };
    editSurvey(obj, history);
  };

  buttonDisableFunc = () => {
    const {hasSections} = this.state;
    if (hasSections !== 0) {
      let dupSections = [...this.state.sections];
      let emptyOption = dupSections.findIndex((list) =>
        (list.title.trim() === '') || list.data.length === 0 || ((list.data.findIndex((question) => question.question.trim() === '' ||  (question.question_type === 4 ? question.options.length !== 0 : question.options.length < 2 || (question.options.findIndex((option) => option.trim() === '') !== -1)))) !== -1)
      );

      if (emptyOption == -1 && dupSections.length >= 2) {
        this.setState({buttonDisable: false});
      }
      else {
        this.setState({buttonDisable: true});
      }
    } else {
      let dupQuestions = [...this.state.questions];
      let emptyOption = dupQuestions.findIndex((list) =>
        (list.question.trim() === '') || (list.question_type === 4 ? list.options.length !== 0 : list.options.length < 2 || (list.options.findIndex((option) => option.trim() === '') !== -1))
      );

      if (emptyOption == -1 && dupQuestions.length >= 2) {
        this.setState({buttonDisable: false});
      }
      else {
        this.setState({buttonDisable: true});
      }
    }
  };

  removePhoto = () => {
    document.getElementById('survey-upload-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };
  updateSurveyQuestions = (questions) => {
    this.setState({
      questions: [...questions]
    }, () => this.buttonDisableFunc());
  };
  updateSurveySections = (sections) => {
    this.setState({
      sections: [...sections]
    }, () => this.buttonDisableFunc());
  };
  toggleSurveyRadioButton = (obj) => {
    this.setState(obj, () => this.buttonDisableFunc());
  };
  onSelectSurveyType = (questionIndex, type, options, sectionIndex) => {
    const {sections, questions, hasSections} = this.state;
    if(hasSections !== 0){
      sections[sectionIndex].data[questionIndex]['options'] = (type === 2) ? [''] : options;
      sections[sectionIndex].data[questionIndex]['question_type'] = type;
      this.setState({sections: sections}, () => this.buttonDisableFunc());
    } else{
      questions[questionIndex]['options'] = (type === 2) ? [''] : options;
      questions[questionIndex]['question_type'] = type;
      this.setState({questions: questions}, () => this.buttonDisableFunc());
    }
  };
  render() {
    const {title, imageName, points, surveyPoints, description, imgSrc, imageUpdated, hasSections, sections, questions, prevQuestionsData, prevSectionsData, buttonDisable} = this.state;
    const {isLoading, surveyDetails} = this.props;
    if (isLoading || !surveyDetails || _.isEmpty(surveyDetails)) {
      return <Waiting />;
    }

    let isDisabled = false;
    if(description === '' || title === '' || imgSrc === '' || imageName === ''){
      isDisabled = true;
    }


    return (
      <Layout>
        <PageTitle>Edit Survey Library</PageTitle>
        <div style={{float: 'left', width: '100%'}}>
          <FormContainer onSubmit={this.saveSurvey}>
            <TitleContainer>
              <FieldTitle>Survey Title:</FieldTitle>
              <StyledInput
                placeholder="Type title here..."
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>Survey Picture:</FieldTitle>
              <div>
                { imageName &&
                              <div>
                                <img src={imageUpdated ? imgSrc : `${ImageUrl}/${imgSrc}`} />
                                <ImageContentContainer>
                                  <div>{imageName}</div>
                                  <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto}>Remove photo</RemovePhotoButton>
                                </ImageContentContainer>
                              </div>
                }
                <ImageInput htmlFor="survey-upload-file" showAtBottom={imageName !== ''}>
                  {imageName ? 'browse new' : 'browse'}
                  <input
                    id="survey-upload-file"
                    type="file"
                    name="user"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>Point Value:</FieldTitle>
                <ActivityDropdown
                  title={points}
                  id="dropdown-basic"
                >
                  {
                    surveyPoints.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelectPoints('points', item)}
                        active={points === item}
                      >
                        {item}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>Survey Description:</FieldTitle>
              <CommonTextArea
                placeholder="Write your description here..."
                rows="8"
                name="description"
                value={description}
                onChange={this.onChangeInput}
                data-gramm_editor="false"
                type="text"
              />
            </TitleContainer>
            <SurveyQuestions
              questions={questions}
              sections={sections}
              prevQuestionsData={prevQuestionsData}
              prevSectionsData={prevSectionsData}
              hasSections={hasSections}
              toggleSurveyRadioButton={this.toggleSurveyRadioButton}
              updateSurveyQuestions={this.updateSurveyQuestions}
              updateSurveySections={this.updateSurveySections}
              onSelectSurveyType={this.onSelectSurveyType}
            />
            <ImageContainer>
              <SaveButton type="submit" disabled={isDisabled || buttonDisable} addMargin>Save Survey</SaveButton>
            </ImageContainer>
          </FormContainer>
        </div>
      </Layout>

    );
  }
}
EditSurveys.propTypes = {
  history: PropTypes.object,
  editSurvey: PropTypes.func,
  isLoading: PropTypes.bool,
  surveyDetails: PropTypes.object
};
const mapStateToProps = (state) => ({
  isLoading: state.surveys.isLoading,
  surveyDetails: state.surveys.surveyDetails
});
const mapDispatchToProps = (dispatch) => ({
  editSurvey: (data, history) => dispatch(editSurvey(data, history))
});
export default connect(mapStateToProps, mapDispatchToProps)(EditSurveys);