import styled from 'styled-components';

const Layout = styled.div`
  Padding: 0 30px;
  width: 100%;
  float: left;
  margin-bottom: 50px;
  
  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  background-color: ${({color}) => color ? 'transparent' : 'white' };
  float: left;
`;

const EditorContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 15px;
`;

const Layoutnew = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 50px;
  
  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;

const Heading = styled.div`
  width: 100%;
  margin-bottom: 25px;
  background: linear-gradient(rgb(82,168,237) 0%,rgb(138,211,247) 100%);
  border-radius: 6px;
  align-items: center;

  margin: auto;
  padding-left: 15px;
  text-transform: capitalize;
  font-family: Rubik-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: white;
  height: 50px;
  display: flex;
`;

export { Layout, FormContainer, EditorContainer, Layoutnew, Heading };