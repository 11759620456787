/* eslint-disable no-console,no-undef, camelcase */
import React,  { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, FormContainer } from './styles';
import { PageTitle } from '../CreateEvent/styles';
import Form from '../Form';
import Waiting from '../Waiting';
import {editWellnessJourney} from "../../redux/actions/educationActions";
import {withTranslation} from 'react-i18next';

const stateForEdit = {
  title: '',
  description: '',
  image: '',
  imageName: '',
  journey_category_id: '',
  selectedOption: 0,
  themeName: '',
  themeMonth: '',
  theme_id: null,
  selectedWellnessGoal:'',
  selectedQuestionId:'',
  selectedLevel:''
};

class EditWellnessJourney extends Component {
  componentDidMount() {
    this.navigateToArticlePage();
  }

  navigateToArticlePage = () => {
    const { location, history, themes } = this.props;
    if(!location.state) {
      history.goBack();
    } else {
      window.console.log("Form", location)
      const imageNameArray = location.state.image.split('/');
      stateForEdit.title = location.state.title;
      stateForEdit.description = location.state.description;
      stateForEdit.imageName = imageNameArray[imageNameArray.length - 1];
      stateForEdit.image = location.state.image;
      stateForEdit.journey_category_id = location.state.journey_category_id;
      stateForEdit.themeId = location.state.theme_id;
      let findThemeIndex = themes.findIndex((v) => v.id === stateForEdit.themeId);
      stateForEdit.themeName = findThemeIndex !== -1 ? themes[findThemeIndex].theme_text : '';
      stateForEdit.themeMonth = findThemeIndex !== -1 ? themes[findThemeIndex].month : '';
      stateForEdit.selectedOption = findThemeIndex !== -1 ? 1 : 0;
      stateForEdit.selectedWellnessGoal = location.state.wellness_goal_id,
      stateForEdit.selectedQuestionId = location.state.question_id,
      stateForEdit.selectedLevel = location.state.levels
    }
  };

  componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location) {
      this.navigateToArticlePage();
    }
  }

  onSubmit = (e, data) => {
    const { editWellnessJourney, location, history } = this.props;
    e.preventDefault();
    let payload = {
      description:data.description,
      image:data.image,
      imageName:data.imageName,
      imageUpdated:data.imageUpdated,
      journey_category_id:data.journey_category_id,
      levels: data.level && data.level.level ? data.level.level : null,
      question_id: data.question && data.question.id ? data.question.id: null,
      wellness_goal_id: data.wellness_goal && data.wellness_goal.id ? data.wellness_goal.id : null,
      selectedOption: data.selectedOption ? data.selectedOption : null,
      themeMonth: data.themeMonth ? data.themeMonth : null,
      themeName: data.themeName ? data.themeName: null,
      theme_id: data.theme_id ? data.theme_id : null,
      title:data.title
    }
    // const editedJourney = {...data};
    payload.id = location.state.id;
    payload.theme_id = data.themeId;
    delete data.themeId;
    editWellnessJourney(payload, history);
  };

  render() {
    const { adventureCategories, isLoading, themes, t, themesHeaderAll } = this.props;
    const editJourney = {
      "fields": [
        {
          "name": "title",
          "type": "text",
          "label": t("Journey Title"),
          "placeholder": t("Test the journey"),
          "mandatory": true,
        },
        {
          "name": "file",
          "type": "file",
          "label": t("Journey Image:"),
          "placeholder": t("Journey Photo"),
          "mandatory": true,
        },
        {
          "name": "journey_category_name",
          "type": "drop-down",
          "label": t("Journey Category:"),
          "placeholder": t("Select Journey Category"),
          "key": "journey_category_id",
          "mandatory": true,
        },
        {
          "name": "wellness_goal",
          "type": "wellness_goal",
          "label": t("Wellness Goal"),
          "placeholder": t("Select Wellness Goal"),
          "key": "wellness_goal",
          "mandatory": false,
        },
        {
          "name": "sub_level",
          "type": "sub_level",
          "label": t("Sub Level (WBA Questions)"),
          "placeholder": t("Select Journey Category"),
          "key": "question",
          "mandatory": false,
        },
        {
          "name": "level",
          "type": "level",
          "label": t("Level"),
          "placeholder": t("Select Level"),
          "key": "level",
          "mandatory": false,
        },
        {
          "name": "description",
          "type": "text",
          "label": t("Journey Description:"),
          "placeholder": t("Description"),
          "mandatory": true,
        }
      ],
      "button": "Save Journey",
      "mandatoryFields": ["title", "image", "journey_category_id", "description"]
    }
    
    if(isLoading) {
      return <Waiting />
    }
    return (
      <Layout>
        <PageTitle>{t("Edit Journey")}</PageTitle>
        <FormContainer>
          <Form
            fields={editJourney.fields}
            buttonText={editJourney.button}
            state={stateForEdit}
            mandatoryFields={editJourney.mandatoryFields}
            onSubmit={this.onSubmit}
            journey_category_id={adventureCategories}
            themes={themes}
            themesHeaderAll={themesHeaderAll}
          />
        </FormContainer>
      </Layout>
    )
  }
}

EditWellnessJourney.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  adventureCategories: PropTypes.array.isRequired,
  themes: PropTypes.array.isRequired,
  editWellnessJourney: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func,
  F: PropTypes.array,
  themesHeaderAll: PropTypes.array

};

const mapStateToProps = (state) => ({
  isLoading: state.education.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  editWellnessJourney: (data, history) => dispatch(editWellnessJourney(data, history))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditWellnessJourney)));